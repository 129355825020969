<template>
    <div>
        <div class="ft20 cl-black cl-main ftw500">代客下单</div>
        <div class="mt20">
            <div class="bg-w pd30" style="min-height: 800px;">
                <div>
                    <a-alert message="代客下单不会直接扣除用户的余额,如果涉及到收款请下完订单后通过去核销用余额或者其他收款方式" banner />
                </div>
                <div class="mt20">
                    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-spin :spinning="showMemberLoading">
                            <a-form-model-item required label="选择用户" v-if="JSON.stringify(form.member) == '{}'">
                                <div class="alcenter flex">
                                    <a-input-search v-model="form.mobile" placeholder="请输入用户手机号"  style="width: 160px" @search="onSearch" />
                                    <div class="ml10 flex alcenter ft12 ftw400 cl-notice" v-if="msgStatus">
                                        <div>暂无该用户，请重新输入或 </div>
                                        <div class="cl-theme clickAct" @click="addMemberAct()">添加会员</div>
                                    </div>
                                </div>
                            </a-form-model-item>

                            <a-form-model-item  label="选中的用户" v-if="JSON.stringify(form.member) != '{}'">
                                <div class="flex alcenter ml10">
                                    <div class="flex alcenter">
                                        <div class="ft14 ftw400 cl-main">昵称：</div>
                                        <div class="ft14 ftw400 cl-black ml5">{{form.member.nick_name}}</div>
                                    </div>

                                    <div class="flex alcenter ml20">
                                        <div class="ft14 ftw400 cl-main">手机号：</div>
                                        <div class="ft14 ftw400 cl-black ml5">{{form.member.mobile}}</div>
                                    </div>

                                    <div class=" ml20">
                                        <a-button type="primary" @click="reChooseAct()">重新选择</a-button>
                                    </div>
                                </div>
                            </a-form-model-item>
                        </a-spin>
                      <a-form-model-item label="选择服务人员" >
                        <div style="width: 400px" @click="chooseServicemanAct()" v-if="JSON.stringify(form.serviceman) == '{}'"
                             class="choose-serviceman-box ml10 clickAct flex center alcenter cl-theme ft14 ftw600">
                          选择<i class="iconfont iconicon_arrowr ft14 ml5" />
                        </div>

                        <div style="width: 400px" v-else class="choose-serviceman-box flex  space">
                          <div class="flex alcenter">
                            <img :src="form.serviceman.face" class="choose-serviceman-box-face" />
                            <div class="ml10">
                              <div class="ft16 ftw600 cl-main text-over4">{{form.serviceman.name}}</div>
                              <div class="mt15 flex alcenter">
                                <i class="iconfont iconcall_gray mr5 ft12 cl-notice" />
                                <div class="cl-info ft12 ftw400 text-over4">{{form.serviceman.mobile}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="flex alcenter cente ft14 ftw600 cl-theme clickAct"
                               @click="chooseServicemanAct()">
                            重新选择<i class="iconfont iconicon_arrowr ft14 ml5" />
                          </div>
                        </div>
                      </a-form-model-item>

                        <a-form-model-item required label="选择次卡" >
                            <a-spin :spinning="showGoodsLoading">
                                <div class="flex alcenter wrap">
                                    <template v-for="(item,index) in chooseType">
                                        <div class="choose-goods-btn active" @click="chooseTypeAct(index)" v-if="item.iden==selectType">{{item.name}}</div>
                                        <div class="choose-goods-btn" v-else @click="chooseTypeAct(index)">{{item.name}}</div>
                                    </template>
                                </div>
                                <div class="mt24" v-if="JSON.stringify(goods) != '{}'">
                                    <div class="show-goods">
                                        <div class="show-goods-item">
                                            <div class="flex space alcenter">
                                                <div class="flex alcenter" style="width: 70%;">
                                                    <img v-if="goods.cover_img != null" :src="goods.cover_img"/>
                                                    <div class="ml10" style="width: 40%;">
                                                        <div  class="text-over">
                                                            {{goods.name}}
                                                        </div>
                                                    </div>

                                                    <div style="margin-left: 80px; width: 20%;">
                                                        <div class="flex alcenter">
                                                            <div v-if="goods.is_flash_sale==1">秒杀价：¥{{goods.flash_price}}</div>
                                                            <div v-else>售价：¥{{goods.price}}</div>
                                                            <div class="ml8">x1</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div >
                                                    <i class="iconfont iconbtn_close ft20 cl-info clickAct" @click="delGoodsAct()"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt16">
                                        <div class="flex alcenter">
                                            <div class="flex alcenter">
                                                <div class="ft12 ftw400 cl-info">合计金额：</div>
                                                <div class="ft12 ftw400 cl-black">¥{{getPrice}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt16">
                                        <div class="flex alcenter">
                                            <div class="ft12 ftw400 cl-info">实付金额</div>
                                            <div class="ml20">
                                                <a-input-number v-model="form.need_pay"  placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-spin>
                        </a-form-model-item>
                        <a-form-model-item required label="支付方式" >
                            <a-radio-group v-model="form.pay_type" name="pay_type"  :default-value="form.pay_type">
                                <a-radio :value="item.code"  v-for="(item,index) in payList" :key="index">{{item.name}}</a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item  label="交易流水号" >
                            <a-input v-model="form.trade_num"  placeholder="请输入"  style="width: 320px;"/>
                        </a-form-model-item>

                        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" >
                            <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
                                确认下单
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>

                </div>
            </div>
        </div>

        <div v-if="chooseGoodsLoading">
            <select-goods :visible="chooseGoodsLoading" v-model="goods"  @cancel="cancelChooseGoods" @ok="okChooseGoods"></select-goods>
        </div>
      <div v-if="chooseServicemanVisible">
        <choose-serviceman v-model="form.serviceman"  project-id="0" :visible="chooseServicemanVisible"
                           @cancel="cancelChooseServiceman" @ok="okChooseServiceman"></choose-serviceman>
      </div>
        <div v-if="addMemberVisible">
            <add-member v-model="form.member" :visible="addMemberVisible" @cancel="cancelAddMember" @ok="okAddMember"></add-member>
        </div>
    </div>
</template>

<script>

    import selectGoods from './components/EditOncecard/modal/selectGoods.vue';
    import addMember from './../mall/components/CustomerToOrder/modal/addMember.vue';
    import chooseServiceman from './../appointment/components/order/modal/addAppointment/modal/chooseServiceman.vue';
    export default{
        components:{
            selectGoods,
            addMember,
            chooseServiceman,
        },
        data(){
            return{
                loading:true,
                showMemberLoading:false,
                showGoodsLoading:false,
                chooseGoodsLoading:false,
                addMemberVisible:false,
                confirmLoading:false,
                chooseServicemanVisible:false,
                labelCol: { span: 4 },
                payList:[],
                wrapperCol: { span: 20 },
                msgStatus:false,
                selectType:'',
                chooseType:[
                    {iden:'normal',name:'次卡选择'},
                ],
                goods:{},
                form:{
                    pay_type:"offline",
                    trade_num:'',
                    member:{},
                    once_card_id:0,
                    need_pay:'',
                    serviceman:{},
                }
            }
        },
        created() {
            this.$http.api('getPayList',{}).then(res=>{
                this.payList=res;

                this.loading=false;

            }).catch(res=>{
                this.loading=false;
            })
        },

        computed:{
            getPrice(){
                let price = 0;
                if(this.goods.length<=0){
                    return price;
                }
                if(this.goods.is_flash_sale==1){
                    price=this.goods.flash_price*100;
                }else{
                    price=this.goods.price*100;
                }

                return price/100;
            },

        },

        methods:{

            chooseTypeAct(index){
                if(this.selectType!=this.chooseType[index].iden){
                    this.selectType=this.chooseType[index].iden;
                    this.goods=new Array;
                    this.form.goods=new Array;
                }
                this.chooseGoodsAct();
            },
        //选择服务人员
          chooseServicemanAct() {
            this.chooseServicemanVisible = true;
          },
          cancelChooseServiceman(){
            this.chooseServicemanVisible = false;
          },
          okChooseServiceman(){
            this.chooseServicemanVisible = false;
          },
            onSearch(){
                if(this.showMemberLoading==true) return;
                this.showMemberLoading=true;
                this.$http.api('admin/getMemberWithMobileFromFakeOrder',{
                    mobile:this.form.mobile,
                }).then(res=>{
                    this.msgStatus=false;
                    this.form.member=res.member;
                    this.showMemberLoading=false;
                }).catch(res=>{
                    this.showMemberLoading=false;
                    this.msgStatus=true;
                })

            },

            reChooseAct(){
                this.msgStatus=false;
                this.form.member=new Object;
            },

            chooseGoodsAct(){
                this.chooseGoodsLoading=true;
            },
            cancelChooseGoods(){
                this.chooseGoodsLoading=false;
            },
            okChooseGoods(){
                this.chooseGoodsLoading=false;
                if(this.goods.length == 0) return; //如果数据为空不请求
                this.form.once_card_id=this.goods.once_card_id;
                this.showGoodsLoading=false;
            },

            delGoodsAct(){
               this.goods={};
               this.form.once_card_id=0;

            },

            addMemberAct(){
                this.addMemberVisible=true;
            },
            cancelAddMember(){
                this.addMemberVisible=false;
            },
            okAddMember(){
                this.msgStatus=false;
                this.addMemberVisible=false;
            },

            changeSwitch(){
                this.form.is_commission=this.form.is_commission == 1 ? 0 :1;
            },

            onSubmit(){


                if(JSON.stringify(this.form.member) == '{}'){
                    this.$message.error('请选择会员');
                    return;
                }

                if(this.form.once_card_id==0){
                    this.$message.error('请选择次卡');
                    return;
                }


                if(this.confirmLoading==true) return;
                this.confirmLoading=true;
                this.$http.api('admin/createFakeOnceCardOrder',{

                    member_id:this.form.member.member_id,
                    once_card_id:this.form.once_card_id,
                    need_pay:this.form.need_pay,
                    pay_type:this.form.pay_type,
                    trade_num:this.form.trade_num,
                   serviceman_id:this.form.serviceman.serviceman_id,

                }).then(res=>{
                    this.$message.success('下单成功',1,()=>{
                        this.confirmLoading=false;
                            this.$router.push('/project/orderOncecard');
                    })
                }).catch(res=>{
                    console.log(res);
                    this.confirmLoading=false;
                })
            }
        }
    }
</script>

<style>
    .show-goods{
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #EBEDF5;
        border-bottom: none;
    }

    .show-goods-item{
        padding: 20px;
        width: 100%;
        border-bottom: 1px solid #EBEDF5;
    }

    .show-goods-item-tag{
        padding: 1px 4px;
        background: rgba(71, 114, 255, 0.1);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #4772FF;
    }

    .show-goods-item img{
        width: 60px;
        height: 60px;
    }

    .choose-goods-btn{
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid #EBEDF5;

        font-size: 12px;
        font-weight: 500;
        color: #232529;
        line-height: 20px;
        margin-right: 15px;
        cursor: pointer;
    }

    .choose-goods-btn.active{
        border: 1px solid #4772FF;
        color: #4772FF;
    }
    .choose-serviceman-box {
      width: 280px;
      height: 80px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #EBEDF5;
      padding: 10px;
      line-height: 1;
    }

    .choose-serviceman-box-face {
      width: 60px;
      height: 60px;
      border-radius: 2px;
    }
</style>
