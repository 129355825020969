<template>
    <div>
        <a-modal title="选择次卡" :width="480" :visible="visible" @cancel="handleCancel" >
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    确定
                </a-button>
            </template>


            <a-spin :spinning="loading">
                <div>
                    <div class="flex center">
                        <a-input-search placeholder="搜索次卡名称" v-model="search.name" style="width: 420px" @search="onSearch" />
                    </div>
                    <div class="mt16">
                        <div class="flex wrap center" v-if="getData.length>0">
                            <div class="choose-goods-it-item flex space" v-for="(item,index) in getData">
                                <div class="flex">
                                    <img v-if="item.cover_img != null" :src="item.cover_img"/>
                                    <div class="ml10">
                                        <div class="ft14 ftw400 cl-black text-over4">{{item.name}}</div>
                                        <div class="ft12 ftw400 cl-theme mt5 " v-if="item.is_flash_sale==1">秒杀价格：¥{{item.flash_price}}</div>
                                        <div class="ft12 ftw400 cl-theme mt5 " v-if="item.is_flash_sale==0">门店价格：¥{{item.price}}</div>
                                        <div class="flex alcenter ft12 ftw400 cl-notice">
                                            <div>次卡总价值：</div>
                                            <div style="text-decoration: line-through;">¥{{item.worth}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex alcenter">
                                    <!-- <i class="iconfont iconadd_small1 ft24 clickAct cl-notice"  /> -->
                                    <div class="choose-goods-it-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
                                        选择
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="flex center mt20">
                            <a-empty/>
                        </div>
                    </div>

                    <div class="flex center mt20">
                        <a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
                    </div>


                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
    import {listMixin} from '../../../../../common/mixin/list.js';

    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            value:{}
        },
        mixins:[listMixin],
        components:{

        },
        data() {
            return {
                loading: false,
                confirmLoading: false,
                select_data:[],
                goods:{},
                pagination:{
                    current:1,
                    pageSize:4,
                    total:0,
                },
                search:{
                    name:'',
                },
                datas:[],

            }
        },
        created() {
            this.getLists();
        },

        computed:{
            getData(){
                let datas =JSON.parse(JSON.stringify(this.datas));
                return datas;
            }
        },

        methods: {
            getLists(){
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('admin/getOnceCardLists',{
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                    name:this.search.name,
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.datas=res.list;
                    this.loading=false;
                }).catch(res=>{
                    console.log(res);
                    this.loading=false;
                })
            },

            onSearch(value){
                this.page=1;
                this.getLists()
            },

            checkIt(index){
                console.log(this.datas[index]);
                this.select_data=this.datas[index];
                this.onSubmit();
            },

            /**
             * 取消弹窗
             */
            handleCancel() {
                this.$emit("cancel");
            },


            onSubmit(){
                if(this.select_data.length<=0){
                    this.$message.error('请选择次卡');
                    return false;
                }
                let value = this.select_data;
                this.$emit('input',value);
                this.$emit("ok");
            },
        }
    }
</script>

<style>
    .choose-goods-it-item{
        width: 540px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #EBEDF5;
        margin-bottom: 10px;
        padding: 10px;
    }

    .choose-goods-it-item img{
        width: 60px;
        height: 60px;
    }

    .choose-goods-it-item-duihao{
        width: 60px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #4772FF;
    }
    .choose-goods-it-item-select{
        width: 60px;
        height: 32px;
        background: #4772FF;
        border-radius: 4px;
        cursor: pointer;
    }


</style>
